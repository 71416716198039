export function getCookie(name: string) {
  const cookies = document.cookie;
  const ca: Array<string> = cookies.split(';');
  const caLen: number = ca.length;
  const cookieName = `${name || 'SessionToken'}=`;
  let c: string;
  let token = ''
  for (let i = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.startsWith(cookieName)) {
      token = c.substring(cookieName.length, c.length) || token;
    }
  }
  return token;
}
export function setCookie(token: string, expired?: Date) {
  const expiredString = expired ? ';expires=' + expired.toUTCString() : '';
  document.cookie = 'SessionToken=' + token + expiredString + "; path=/";
  if(!token)  document.cookie = '';
}

export function getParams<T extends object>(req: T) {
  const obj: Record<string, string | number> = {}
  Object.keys(req).forEach(key => {
    const value = req[key as keyof T];

    if (value !== null && value !== undefined) {
      if (value instanceof Date) {
        obj[key] = value.toISOString();
      } else if (typeof value === 'string' && isISODateString(value)) {
        obj[key] = convertDateTime(new Date(value));
      } else
        obj[key] = req[key as keyof T] as (string | number)
    }

  })
  return obj;
}

function convertDateTime(date: Date): string {
  return date.toISOString();
}

function isISODateString(str: string): boolean {
  const isoDatePattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z/;
  return isoDatePattern.test(str);
}
